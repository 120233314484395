import React from 'react';
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';

export default function Layout(props) {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="h-3 bg-top-pattern bg-repeat-x bg-contain" />

      <Header />

      <main className="flex-grow">
        {props.children}
      </main>

      <Footer />
    </div>
  );
}
