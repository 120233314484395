import { classNames } from "@/utils";
import React from "react";

export default function SectionTitle({
  tag = "h1",
  isInverted = false,
  isTagReversed = false,
  ...props
}) {
  const TitleTag = isTagReversed ? "p" : tag;
  const SubtitleTag = isTagReversed ? tag : "p";

  return (
    <div
      className={classNames(
        "max-w-7xl mx-auto mt-10 sm:mt-16 px-4 sm:text-center",
        props.className
      )}
    >
      <SubtitleTag
        className={classNames(
          "text-sm sm:text-base font-semibold tracking-wide uppercase",
          !isInverted ? "text-green-600" : "text-[#043c2e]"
        )}
      >
        {props.subtitle}
      </SubtitleTag>

      <TitleTag
        className={classNames(
          "mt-2 font-bold tracking-tight",
          tag === "h1"
            ? "text-3xl sm:text-5xl lg:text-6xl"
            : "text-2xl sm:text-4xl lg:text-5xl",
          !isInverted ? "text-gray-900" : "text-white"
        )}
      >
        {props.title}
      </TitleTag>

      <p
        className={classNames(
          "sm:max-w-3xl mx-auto mt-6 md:mt-12 text-base sm:text-lg md:text-xl",
          !isInverted ? "text-gray-500" : "text-green-200"
        )}
      >
        {props.children}
      </p>
    </div>
  );
}
