import HeaderMobile from "@/components/common/HeaderMobile";
import LangSwitch from "@/components/common/LangSwitch";
import Logo from "@/components/common/Logo";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, PhoneIcon } from "@heroicons/react/outline";
import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function Header() {
  const { locale } = useLocalization();
  const { t } = useTranslation("common");

  return (
    <header>
      <Popover className="relative bg-white">
        {({ open }) => (
          <>
            <div className="flex justify-between md:justify-start items-center md:space-x-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-6">
              <div className="flex justify-start text-xl lg:w-0 lg:flex-1">
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <div className="-my-2 -mr-2 md:hidden">
                <Popover.Button className="inline-flex justify-center items-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-600">
                  <span className="sr-only">{t("header.open.label")}</span>
                  <MenuIcon className="w-6 h-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <Popover.Group
                as="nav"
                className="hidden md:flex space-x-10 text-base font-medium text-gray-500"
              >
                {locale === "pl" && (
                  <Link to="/dla-pracodawcy" className="hover:text-gray-900">
                    {t("header.employer.label")}
                  </Link>
                )}

                <Link to="/dla-pracownika" className="hover:text-gray-900">
                  {t("header.employee.label")}
                </Link>

                <span>
                  <PhoneIcon className="absolute w-6 h-6" aria-hidden="true" />
                  <a
                    href="tel:+48509890730"
                    target="_blank"
                    rel="noreferrer"
                    className="ml-7 hover:text-gray-900"
                  >
                    +48 509 890 730
                  </a>
                </span>

                <LangSwitch />
              </Popover.Group>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute inset-x-0 top-0 z-30 p-2 transition transform origin-top-right md:hidden"
              >
                <HeaderMobile />
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </header>
  );
}
