import React from "react";

export default function Logo() {
  return (
    <span className="font-bold">
      <span className="text-green-600 xl:inline">Yes!</span>
      <span className="text-black xl:inline">People</span>
    </span>
  );
}
