import { LocalizedLink as Link } from "gatsby-theme-i18n";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation("common");
  const year = new Date().getFullYear();

  return (
    <footer
      className="mt-16 lg:mt-24 bg-gray-100"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        {t("footer.label")}
      </h2>
      <div className="max-w-7xl mx-auto mt-8 px-4 sm:px-6 lg:px-8">
        <div className="sm:grid sm:grid-cols-2 lg:grid-cols-5 sm:gap-x-6 lg:gap-x-8">
          <div className="lg:ml-0 lg:col-span-2">
            <p className="text-base font-semibold tracking-wide text-gray-400 uppercase">
              {t("footer.address.label")}
            </p>
            <address className="mt-2 text-gray-500">
              Yes!People sp. z o.o.
              <br />
              al. Wiśniowa 36A lok. 308
              <br />
              53-137 Wrocław
              <br />
            </address>
          </div>

          <div className="mt-4 sm:mt-0">
            <p className="text-base font-semibold tracking-wide text-gray-400 uppercase">
              {t("footer.phone.label")}
            </p>
            <div className="mt-2">
              <a
                href="tel:+48509890730"
                target="_blank"
                rel="noreferrer"
                className="text-gray-500 hover:text-gray-900"
              >
                +48 509 890 730
              </a>
            </div>
          </div>

          <div className="mt-4 sm:mt-0">
            <p className="text-base font-semibold tracking-wide text-gray-400 uppercase">
              {t("footer.email.label")}
            </p>
            <div className="mt-2">
              <a
                href="mailto:biuro@yespeople.pl"
                target="_blank"
                rel="noreferrer"
                className="text-gray-500 hover:text-gray-900"
              >
                biuro@yespeople.pl
              </a>
            </div>
          </div>

          <div className="mt-4 sm:mt-0">
            <p className="text-base font-semibold tracking-wide text-gray-400 uppercase">
              {t("footer.links.label")}
            </p>
            <div className="mt-2">
              <Link
                to="/polityka-prywatnosci"
                className="text-gray-500 hover:text-gray-900"
              >
                {t("footer.links.privacyPolicy")}
              </Link>
            </div>
          </div>
        </div>

        <div className="pt-8 my-8 border-t border-gray-200">
          <p className="text-base text-gray-400 sm:text-center">
            <Trans i18nKey="footer.copyrights">{{ year }}</Trans>
          </p>
        </div>
      </div>
    </footer>
  );
}
