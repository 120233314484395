import React from "react";
import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n";
import { GlobeAltIcon } from "@heroicons/react/outline";

export default function LangSwitch() {
  const { locale } = useLocalization();

  return (
    <div className="relative pl-5 space-x-4 text-base font-medium text-gray-500">
      <GlobeAltIcon
        className="absolute top-0 left-0 w-6 h-6"
        aria-hidden="true"
      />
      {locale !== "pl" && (
        <Link to="/" language="pl" className="hover:text-gray-900">
          PL
        </Link>
      )}
      {locale !== "uk" && (
        <Link to="/" language="uk" className="hover:text-gray-900">
          УК
        </Link>
      )}
      {locale !== "ru" && (
        <Link to="/" language="ru" className="hover:text-gray-900">
          РУ
        </Link>
      )}
    </div>
  );
}
