import Logo from "@/components/common/Logo";
import { Popover } from "@headlessui/react";
import { PhoneIcon, GlobeAltIcon, XIcon } from "@heroicons/react/outline";
import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n";
import React from "react";
import { useTranslation } from "react-i18next";

export default function HeaderMobile() {
  const { locale } = useLocalization();
  const { t } = useTranslation("common");

  return (
    <div className="bg-white rounded-lg divide-y-2 divide-gray-50 ring-1 ring-black ring-opacity-5 shadow-lg">
      <div className="px-5 pt-5 pb-6">
        <div className="flex justify-between items-center text-xl">
          <Link
            to="/"
            className="flex items-center p-3 -m-3 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-600"
          >
            <Logo />
          </Link>
          <div className="-mr-2">
            <Popover.Button className="inline-flex justify-center items-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-600">
              <span className="sr-only">{t("header.close.label")}</span>
              <XIcon className="w-6 h-6" aria-hidden="true" />
            </Popover.Button>
          </div>
        </div>
        <div className="mt-6">
          <nav className="grid grid-cols-1 gap-7 text-base font-medium text-gray-500">
            {locale === "pl" && (
              <Link
                to="/dla-pracodawcy"
                className="flex items-center p-3 -m-3 rounded-lg hover:bg-gray-50 hover:text-gray-900"
              >
                {t("header.employer.label")}
              </Link>
            )}
            <Link
              to="/dla-pracownika"
              className="flex items-center p-3 -m-3 rounded-lg hover:bg-gray-50 hover:text-gray-900"
            >
              {t("header.employee.label")}
            </Link>

            <hr />

            <a
              href="tel:+48509890730"
              target="_blank"
              rel="noreferrer"
              className="relative flex items-center p-3 -m-3 pl-12 rounded-lg hover:bg-gray-50 hover:text-gray-900"
            >
              <PhoneIcon
                className="absolute w-6 h-6 left-3"
                aria-hidden="true"
              />
              +48 509 890 730
            </a>

            {locale !== "pl" && (
              <Link
                to="/"
                language="pl"
                className="relative flex items-center p-3 -m-3 pl-12 rounded-lg hover:bg-gray-50 hover:text-gray-900"
              >
                <GlobeAltIcon
                  className="absolute w-6 h-6 left-3"
                  aria-hidden="true"
                />
                PL
              </Link>
            )}
            {locale !== "uk" && (
              <Link
                to="/"
                language="uk"
                className="relative flex items-center p-3 -m-3 pl-12 rounded-lg hover:bg-gray-50 hover:text-gray-900"
              >
                <GlobeAltIcon
                  className="absolute w-6 h-6 left-3"
                  aria-hidden="true"
                />
                УК
              </Link>
            )}
            {locale !== "ru" && (
              <Link
                to="/"
                language="ru"
                className="relative flex items-center p-3 -m-3 pl-12 rounded-lg hover:bg-gray-50 hover:text-gray-900"
              >
                <GlobeAltIcon
                  className="absolute w-6 h-6 left-3"
                  aria-hidden="true"
                />
                РУ
              </Link>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
}
